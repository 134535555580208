.heading-safety {
	position: relative;
	padding-top: 50px;
	font-size: 26px;
	text-align: center;
}

.heading-safety span {
	position: relative;
	z-index: 2;
}

.heading-safety::before {
	content: attr(data-en);
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	color: rgba(48, 100, 255, 0.2);
	font-size: 80px;
	font-style: italic;
}

.safe-instructions a {
    display: block; /* この行を追加して<a>タグをブロック要素として表示 */
    width: 100%;    /* この行も追加 */
}

.safe-instruction {
    width: 100%;
    display: block; /* imgがブロック要素として表示されるように */
}

.shiori {
	max-width: 900px;
	width: 100%;
	margin: 0 auto;
}

/* 以下のメディアクエリを追加 */
@media (min-width: 834px) {
    .safe-instructions a {
        display: inline-block; /* PCでは横並びにするためにインラインブロックとして表示 */
        width: 50%;            /* 2つの画像を横並びに表示するために50%の幅を設定 */
    }
}
