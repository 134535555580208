.heading-members {
	position: relative;
	padding-top: 50px;
	font-size: 26px;
	text-align: center;
}

.heading-members span {
	position: relative;
	z-index: 2;
}

.heading-members::before {
	content: attr(data-en);
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	color: rgba(84, 224, 66, 0.2);
	font-size: 80px;
	font-style: italic;
}

.members_title {
	text-align: center;
	font-size: 4em;
	font-weight: bold;
}
.profile_pic {
	width: 140px;
	height: 140px;
	margin: 10px 10px;
	border-radius: 70px;
}
.profile_pic_anii {
	box-shadow: 7px 7px 0 #ffd2d2;
}
.profile_pic_mark {
	box-shadow: 7px 7px 0 #d1ffcd;
}
.profile_pic_taro {
	box-shadow: 7px 7px 0 #d2f6ff;
}

.profile_content {
	float: left;
	max-width: 540px;
}
.profile_content p{
	margin: 0rem;
}
.profile_name {
	font-size: 1.5em;
	color: grey;
	font-weight: bold;
}
.profile_icon {
	display: inline-block;
	height: 30px;
	width: 30px;
	margin: 2.5px;
}
.profile-details {
	color: grey;
}
.members_main {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.members_below {
	height: calc(100vh - 953px);
}
.members_title {
	font-size: 3.5em;
	padding: 0px;
	margin-top: 60px;
	margin-bottom: 35px;
}
.profile {
	width: 80%;
	max-width: 700px;
	min-height: 265px;
	padding: 25px 0px 90px 0px;
	margin: 0px auto;
}

/*タブレット用*/
@media(max-width: 800px) {
	.profile_content {
		float: none;
		margin: 0 auto;
	}
	.profile_content_pic {
		margin: 0px calc((100% - 160px)/2);
	}
	.profile_name, .members_sns {
		text-align: center;
	}
	
}
/*スマホ用*/
@media(max-width: 600px) {
	.profile-details {
		font-size: 18px;
		color: grey;
		padding: 0px 0px 7px 0px;
	}
	.profile_content_pic {
		margin: 0px calc((100% - 160px)/2);
	}
	.profile_name {
		font-size: 18px;
		color: grey;
		text-align: center;
	}
}