.heading-project-detail-title {
	position: relative;
	padding-top: 50px;
	font-size: 26px;
	text-align: center;
}

.heading-project-detail-title span {
	position: relative;
	z-index: 2;
}

.heading-project-detail-title::before {
	content: attr(data-en);
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	color: rgba(84, 224, 66, 0.2);
	font-size: 80px;
	font-style: italic;
}

.project-detail-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.project-detail-top-pic {
    max-width: 100%;
}

.heading-project-detail-subtitle {
    text-align: center;
    font-size: 25px;
}

.link-outside {
    text-align: center;
    font-size: 15px;
}

.project-detail-description {
	text-align: center;
}

img.project-detail-description-pic {
	width: 500px;
    max-width: 100%;
	margin: 0px auto;
}

div.project-detail-description-pic{
	text-align: center;
}