p.late-title {
    text-align: center;
    font-family: 'Yuji Syuku', serif;
    font-size: 4em;
    margin: 0.2em 0;
}
p.late-reason {
    text-align: center;
    font-family: 'Yuji Syuku', serif;
    font-size: 10em;
    margin: 0.1em 0;
    line-height: 1em
}
p.late-genre {
    text-align: center;
    font-family: 'Yuji Syuku', serif;
    font-size: 4em;
    margin: 0.2em 0;
}
p.late-level {
    text-align: center;
    font-family: 'Yuji Syuku', serif;
    font-size: 2em;
    margin: 0.2em 0;
}
img.share-sns {
    height: 2.5rem;
    margin: 0 0.2em;
}

/* star from here */
.star5_rating{
    position: relative;
    z-index: 0;
    display: inline-block;
    white-space: nowrap;
    color: #CCCCCC; /* グレーカラー 自由に設定化 */
    /*font-size: 30px; フォントサイズ 自由に設定化 */
}

.star5_rating:before, .star5_rating:after{
    content: '★★★★★';
}

.star5_rating:after{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    color: #ffcf32; /* イエローカラー 自由に設定化 */
}

.star5_rating[data-rate="5"]:after{ width: 100%; } /* 星5 */
.star5_rating[data-rate="4.5"]:after{ width: 90%; } /* 星4.5 */
.star5_rating[data-rate="4"]:after{ width: 80%; } /* 星4 */
.star5_rating[data-rate="3.5"]:after{ width: 70%; } /* 星3.5 */
.star5_rating[data-rate="3"]:after{ width: 60%; } /* 星3 */
.star5_rating[data-rate="2.5"]:after{ width: 50%; } /* 星2.5 */
.star5_rating[data-rate="2"]:after{ width: 40%; } /* 星2 */
.star5_rating[data-rate="1.5"]:after{ width: 30%; } /* 星1.5 */
.star5_rating[data-rate="1"]:after{ width: 20%; } /* 星1 */
.star5_rating[data-rate="0.5"]:after{ width: 10%; } /* 星0.5 */
.star5_rating[data-rate="0"]:after{ width: 0%; } /* 星0 */
/* star to here */
/* generate button from here */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

button.btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

.late-button {
    text-align: center;
}

.btn,
button.btn {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}

button.btn--red.btn--cubic {
  border-bottom: 5px solid #9f000c;
}
  
button.btn--red.btn--cubic:hover {
  margin-top: 3px;
  border-bottom: 2px solid #9f000c;
}

button.btn--radius {
  border-radius: 100vh;
}

.fa-position-right {
  position: absolute;
  top: calc(50% - .5em);
  right: 1rem;
}
.btn--red, button.btn--red, button.btn--red {
    color: #fff;
    background-color: #d20010;
}
/* generate button to here */

/*タブレット用*/
@media(max-width: 800px) {
    p.late-title {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 8em;
        margin: 0.4em 0;
    }
    p.late-reason {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 20em;
        margin: 0.2em 0;
        line-height: 1em
    }
    p.late-genre {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 8em;
        margin: 0.4em 0;
    }
    p.late-level {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 4em;
        margin: 0.4em 0;
    }
    img.share-sns {
        height: 5rem;
        margin: 0 0.4em;
    }

    /* generate button from here */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

button.btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

.late-button {
    text-align: center;
}

.btn,
button.btn {
  font-size: 6rem;
  font-weight: 700;
  line-height: 3;
  position: relative;
  display: inline-block;
  padding: 2rem 8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.2em;
  color: #212529;
  border-radius: 1rem;
}

button.btn--red.btn--cubic {
  border-bottom: 10px solid #9f000c;
}
  
button.btn--red.btn--cubic:hover {
  margin-top: 6px;
  border-bottom: 4px solid #9f000c;
}

button.btn--radius {
  border-radius: 200vh;
}

.fa-position-right {
  position: absolute;
  top: calc(50% - 1em);
  right: 2rem;
}
.btn--red, button.btn--red, button.btn--red {
    color: #fff;
    background-color: #d20010;
}
/* generate button to here */
}
/*スマホ用*/
@media(max-width: 600px) {
    p.late-title {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 8em;
        margin: 0.4em 0;
    }
    p.late-reason {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 15em;
        margin: 0.2em 0;
        line-height: 1em
    }
    p.late-genre {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 6em;
        margin: 0.4em 0;
    }
    p.late-level {
        text-align: center;
        font-family: 'Yuji Syuku', serif;
        font-size: 4em;
        margin: 0.4em 0;
    }
    img.share-sns {
        height: 8rem;
        margin: 0 0.4em;
    }

    /* generate button from here */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

button.btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

.late-button {
    text-align: center;
}

.btn,
button.btn {
  font-size: 4rem;
  font-weight: 700;
  line-height: 3;
  position: relative;
  display: inline-block;
  padding: 2rem 8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.2em;
  color: #212529;
  border-radius: 1rem;
}

button.btn--red.btn--cubic {
  border-bottom: 10px solid #9f000c;
}
  
button.btn--red.btn--cubic:hover {
  margin-top: 6px;
  border-bottom: 4px solid #9f000c;
}

button.btn--radius {
  border-radius: 200vh;
}

.fa-position-right {
  position: absolute;
  top: calc(50% - 1em);
  right: 2rem;
}
.btn--red, button.btn--red, button.btn--red {
    color: #fff;
    background-color: #d20010;
}
/* generate button to here */
}