.top-logo {
    height: calc(100vh - 74px);
    overflow: hidden;
    position: relative;
}
.top-logo img {
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 48%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.top-message {
    text-align: center;
    color: rgb(116, 116, 116);
    max-width: 90vw;
    margin: 0 auto 150px auto;
}

.anim-box.zoomin.is-animated {
    animation: zoomIn 0.3s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}
  
@keyframes zoomIn {
    0% {
      transform: scale(10);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}  

/*スマホ用*/
@media(max-width: 600px) {
    .top-message {
        font-size: 17px;
    }
}