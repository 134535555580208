.heading-projects {
	position: relative;
	padding-top: 50px;
	font-size: 26px;
	text-align: center;
}

.heading-projects span {
	position: relative;
	z-index: 2;
}

.heading-projects::before {
	content: attr(data-en);
	position: absolute;
	top: -8px;
	left: 50%;
	transform: translateX(-50%);
	color: rgba(224,66,114,0.2);
	font-size: 80px;
	font-style: italic;
}

.project_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.project {
	width: 100%;
	max-width: 1200px;
	padding: 30px 0px;
	margin: 0 auto;
}
.project:after {
  display: block;
  clear: both;
  content: '';
}
.project_content_pic {
	float: left;
	width: 40%;
	margin: 30px 2.5% auto 2.5%;
}
.project_content {
	float: left;
	width: 50%;
	margin: 20px 2.5% auto 2.5%;
}
.project_title {
	text-align: center;
	font-size: 4em;
	font-weight: bold;
}
.project_name {
	font-weight: bold;
	font-size: 40px;
	padding: 0px 10px;
	margin: 0;
}
.project_description {
	font-size: 20px;
	color: grey;
}
.project_pic {
    height: auto;
    display: block;
    max-width: 100%;
	margin: 0 auto;
}
.project-pic-round {
	border-radius: 20px;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .2);
}
.link-projects {
    color: rgb(116, 116, 116);
}

.award-ribbon {
	display: inline-block;
	position: relative;
	height: 40px;/*高さ*/
	line-height: 15px;/*高さ*/
	text-align: center;
	padding: 0 40px 0 18px;/*文字の左右の余白*/
	font-size: 18px;/*文字サイズ*/
	/* background: #ffc668;背景色 */
	background-image: -webkit-linear-gradient(315deg, #704308 0%, #ffce08 40%, #e1ce08 60%, #704308 100%);
  	background-image: linear-gradient(135deg, #704308 0%, #ffce08 40%, #e1ce08 60%, #704308 100%);
	color: #FFF;/*文字色*/
	box-sizing: border-box;
	margin: 5px auto;
}
  
.award-ribbon:after {
	position: absolute;
	content: '';
	width: 0px;
	height: 0px;
	z-index: 1;
}
  
.award-ribbon:after {
	top: 0;
	right: 0;
	border-width: 20px 15px 20px 0px;
	border-color: transparent #fff transparent transparent;
	border-style: solid;
}

/*タブレット用*/
@media(max-width: 800px) {
    .project_content_pic {
        float: none;
        width: calc(90% - 8px);
		margin: auto 5%;
    }
    .project_content {
        float: none;
        width: calc(80% - 8px);
		margin: auto 10%;
    }
 
    .project_content_pic {
        margin-bottom: 1.5rem;
    }
	.project_name {
		text-align: center;
	}
	.project_name-dayone {
		max-width: 480px;
		margin: 0 auto;
	}
	.menu-toggle {
		margin: 0px 10px 0px auto;
    }
}
/*スマホ用*/
@media(max-width: 600px) {
    html {
        font-size: 5px;
    }
  
    .project_content_pic {
        width: calc(95% - 8px);
		margin: auto 2.5%;
        padding: 4px;
    }
    .project_content {
        width: calc(85% - 8px);
		margin: auto 7.5%;
        padding: 4px;
    }
  
    .project_content_pic {
        margin-bottom: 1rem;
    }
	.project_name {
		font-size: 35px;
		text-align: center;
	}
	.project_name-dayone {
		max-width: 440px;
		margin: 0 auto;
	}
	.menu-toggle {
		margin: 0px 10px 0px auto;
    }

	.award-ribbon {
		height: 38px;/*高さ*/
		line-height: 14px;/*高さ*/
		font-size: 16px;/*文字サイズ*/
	}  
	.award-ribbon:after {
		border-width: 19px 15px 19px 0px;
	}	
}