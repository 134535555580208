header {
    width: 96%;
    padding: 10px 2% 10px;
    background-color: rgb(31, 31, 31);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 20;
}

h1.h1-header-logo {
    margin: 0;
    padding: 0;
    font-size: 20px;
}
.header-logo {
    height: 35px;
    mix-blend-mode: screen;
}
div.nav-sp {
   display: none;
}

a {	
   text-decoration: none;
   color: #ffffff;
}
ul {
   list-style: none;
   margin: 0;
   display: flex;
}
li {
   margin: 0 0 0 15px;
   font-size: 14px;
}
nav {
   margin: 0 0 0 auto;
}

/*スマホ用*/
@media(max-width: 650px) {
   nav.nav-pc {
      display: none;
   }
   div.nav-sp {
      display: block;
   }
   h1.h1-header-logo {
      width: 93.75px;
      margin: 0 auto;
      padding: 0;
      font-size: 20px;
   }
}